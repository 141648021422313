<template>
    <div>
        <Quote/>
        <GoBack next="Kurslarim"/>
        <div class="container">
            <div class="row justify-content-center">
                <div class="d-block d-md-none col mt-3">
                    <table class="table table-hover border" v-if="getMyCourses.length > 0">
                        <tbody
                            v-for="course in filteredData"
                            :key="course.id"
                        >
                        <tr>
                            <th scope="col">id:</th>
                            <td>{{ course.course.id }}</td>
                        </tr>
                        <tr>
                            <th scope="col">Nomi:</th>
                            <td>{{ course.course.name }}</td>
                        </tr>
                        <tr>
                            <th scope="col">Vaqti:</th>
                            <td>{{ course.course.startMonth.name }}</td>
                        </tr>
                        <tr>
                            <th class="pb-3" scope="col">Guruh:</th>
                            <td style="font-size: x-small">
                                <a :href="course.course.link" target="_blank">
                                    <div class="hover-decoration pt-1">
                                        {{ course.course.link }}
                                    </div>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <h3
                    v-else
                        class="text-center mt-4 mb-4"
                    >
                        Hozircha sizning kurslaringiz mavjud emas:
                    </h3>
                </div>
                <div class="d-none d-md-block col">
                    <table class="table table-hover border" v-if="getMyCourses.length > 0">
                        <thead>
                        <tr>
                            <th scope="col">id</th>
                            <th scope="col">
                                <input
                                    v-model="searchCourseInformation.name"
                                    type="text"
                                    @input="searchFilter"
                                    placeholder="Nomi"
                                    style="width: 145px;border-radius: 5px"
                                />
                            </th>
                            <th scope="col">
                                <input
                                    v-model="searchCourseInformation.startMonth"
                                    type="text"
                                    @input="searchFilter"
                                    placeholder="Boshlanish vaqti"
                                    style="width: 145px;border-radius: 5px"
                                />
                            </th>
                            <th scope="col">
                                <input
                                    v-model="searchCourseInformation.link"
                                    @input="searchFilter"
                                    type="text"
                                    placeholder="Telegram guruhi linki"
                                    style="width: 145px;border-radius: 5px"
                                />
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="course in filteredData"
                            :key="course.id"
                        >
                            <th scope="row">{{ course.course.id }}</th>
                            <td>{{ course.course.name }}</td>
                            <td>{{ course.course.startMonth.name }}</td>
                            <td colspan="2">
                                <a :href="course.course.link" target="_blank">
                                    <div class="hover-decoration">
                                        {{ course.course.link }}
                                    </div>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <h3
                        v-else
                        class="text-center mt-4 mb-4"
                    >
                        Hozircha sizning kurslaringiz mavjud emas:
                    </h3>
                </div>
            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import GoBack from "../components/GoBack";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "MyCoursesPage",
    components: {GoBack, Quote},
    data() {
        return {
            show: true,
            filteredData: [],
            searchCourseInformation: {
                name: '',
                startMonth: '',
                link: '',
                page: 1
            },
        }
    },
    methods: {
        ...mapActions(['fetchMyBoughtCourses', 'fetchMyCourses', 'fetchUser', 'searchCourseInfo']),
        searchFilter() {
            this.filteredData = []
            this.getMyCourses.forEach((course) => {
                if (course.course.name.toLowerCase().indexOf(this.searchCourseInformation.name.toLowerCase()) > -1 &&
                    course.course.startMonth.name.toLowerCase().indexOf(this.searchCourseInformation.startMonth.toLowerCase()) > -1 &&
                    course.course.link.toLowerCase().indexOf(this.searchCourseInformation.link.toLowerCase()) > -1
                ) {
                    this.filteredData.push(course)
                }
            })
        }
    },
    computed: {
        ...mapGetters(['getMyBoughtCourses', 'getUserInfo', 'getMyCourses', 'getTakeCourses'])
    },
    mounted() {
        this.show = true
        this.fetchUser()
            .then(() => {
                this.fetchMyCourses()
                    .then(() => {
                        console.log(this.getMyCourses)
                        this.searchFilter()
                    })
            })
        this.show = false
    }
}
</script>

<style scoped>

img {
    width: 20px;
    height: 20px;
}

input {
    border: 1px solid black;
}

input::placeholder {
    font-size: 12px;
    padding: 0.5rem;
}

input:focus {
    border: none;
    outline: 2px solid #b505b4;
}

@media (max-width: 768px) {
    .texts {
        font-size: 3vw;
    }

    input {
        font-size: 2.7vw;
        border: 1px solid black;
    }
}

@media (max-width: 500px) {
    .texts {
        word-break: break-all;
        font-size: 12px;
    }

    input {
        font-size: 3.5vw;
        border: 1px solid black;
    }
}

</style>
